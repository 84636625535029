import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

@HttpExclude()
export class BlockchainVersionCollection extends ExpansibleCollection<
  BlockchainVersion
> {
  resource?: IBlockchainVersionCollectionResourcesHolder

  @RequestExpose() networkIdentifier: string | null = null
  @RequestExpose() limit: number | null = null

  constructor() {
    super(BlockchainVersion)
  }

  queryAsPage() {
    return this.listBlockchainVersion()
  }

  async listBlockchainVersion() {
    return await Request.get(`/client/blockchain-version`, {
      params: this.params,
    })
      .name('listBlockchainVersion')
      .as(this)
      .getResponse()
  }

  async listExportBlockchainVersion() {
    return await Request.get(`/client/blockchain-version/export`, {
      params: this.params,
    })
      .name('listExportBlockchainVersion')
      .as(this)
      .getResponse()
  }
}

export interface IBlockchainVersionCollectionResourcesHolder {}
